// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.body {
  background-color: white;
}

.css-g1d714-ValueContainer1 {
  background-color: white;
  color: black;
}

.app-header .navbar-toggler-icon {
  // background-image: url("./assets/img/brand/menu.svg");
  background-color: white;
  border-radius: 10px;
}

// .app-header .navbar-toggler:hover .navbar-toggler-icon {
//   // background-image: url("./assets/img/brand/menu.svg");
// }
.create,
.react-confirm-alert-overlay {
  background-color: #1d6aab;
  box-shadow: 0px 2px 2px rgb(66, 66, 66);
  color: "white";
  border: none;
}

.react-confirm-alert-body {
  background: transparent;
  border: 1px solid white;
}

.Toaster__alert {
  // background-image: linear-gradient(#fa6e3f, #7d3720);
}

.react-confirm-alert-body {
  color: white;
}

.Toaster__alert_text {
  color: #1d6aab;
}

.delivered {
  background-image: linear-gradient(#3498db, #1a4c6e);
}

.rejected {
  background-image: linear-gradient(#a10000, #510000);
}

.tabCard {
  background-color: #1d6aab;
}

.notFound {
  background-image: linear-gradient(#fa6e3f, #7d3720);
}

.tracking {
  background-image: linear-gradient(#808080, #585858);
}

.imgUplaod {
  background-color: white;
  color: #1d6aab;
  border: 2px solid white;
  width: 75%;
  height: 90%;
  align-items: center;
}

.search,
.btn-sc,
.btn-active,
.react-confirm-alert-button-group > button {
  background-color: white;
  border: none;
  color: #1d6aab;
  box-shadow: 0px 2px 2px rgb(66, 66, 66);
}

.btn-inActive {
  background-color: #1d6aab;
  border: 1px solid white;
  color: white;
  box-shadow: 0px 2px 2px rgb(66, 66, 66);
}

.btn-file {
  background-color: white;
  border: none;
  color: #1d6aab;
  box-shadow: 0px 2px 2px rgb(66, 66, 66);
}

.btn-sc:hover {
  background-color: #1d6aab;
  border: 1px solid white;
  color: white;
  box-shadow: none;
}

.login {
  background: rgba(160, 160, 160, 0.4);
}

.usertype {
  background-image: linear-gradient(#808080, #fa6e3f);
}

.rate {
  background-color: rgba(227, 169, 150, 0.3);
}

.app-header {
  border: none;
  background-color: #1d6aab;
  box-shadow: 0px 0px 10px black;
  font-weight: bold;
}

.breadcrumb {
  background-color: inherit;
  border: none;
  font-weight: bold;
}

.sidebar {
  background-color: #1d6aab;

  font-weight: 400;
  font-weight: 500;
}

.breadcrumb-item a {
  color: #fa6e3f;
}

.breadcrumb-item.active a {
  color: #fa6e3f;
}

.sidebar .nav-link.active {
  background-color: white;
  border-top-right-radius: 10%;

  color: #0078de;
}
.sidebar .nav-dropdown.open .nav-link {
  background-color: #1d6aab;
  color: white;
}
.sidebar .nav-dropdown.open .nav-link.active {
  background-color: white;
  color: #1d6aab;
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .sidebar-minimizer {
    background-color: #1d6aab;
  }
}

.css-g1d714-ValueContainer .css-1wa3eu0-placeholder {
  color: rgb(255, 255, 255);
}
.css-g1d714-ValueContainer1 .css-1wa3eu0-placeholder {
  color: black;
}

.sidebar .nav-link:hover {
  background-color: #0078de;
  color: white;
  font-weight: bold;
}
.currancy_input {
  width: 100%;
  background-color: transparent;
  border-color: #c8ced3;
  border-radius: 4px;
}

.breadcrumb-item.active {
  color: #fa6e3f;
  border-top-right-radius: 4px;
}

.sidebar .nav-link.active .nav-icon {
  // color: white;
}

.sidebar .nav-link .nav-icon {
  color: white;
  font-size: 15px;
}

.d-md-down-none {
  // background-color: #121212;
  background-color: transparent;
}

div .css-1pahdxg-control {
  background-color: transparent;
}

.css-2b097c-container
  .css-yk16xz-control
  .css-g1d714-ValueContainer
  .css-1uccc91-singleValue {
  color: white;
}
.css-1uccc91-singleValue {
  color: white;
}

.app-header .navbar-nav {
  // background-color: #121212;
  background-color: transparent;
  color: white;
}

div .css-14jk2my-container .css-1fhf3k1-control,
div .css-2b097c-container .css-yk16xz-control {
  background-color: transparent;
  color: white;
}

// ::placeholder {
//   color: white;
// }

.css-g1d714-ValueContainer {
  font-size: 13px;
  font-weight: bold;
  font-family: Titillium Web;
  color: white;
}

.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) {
  color: white;
}
.MuiSelect-select.MuiSelect-select,
.css-b8ldur-Input {
  color: white;
}

.react-tel-input .flag-dropdown {
  top: auto;
  background-color: transparent;
}
.react-tel-input .selected-flag {
  position: relative;
  margin-top: 64%;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.75rem 0.75rem;
  background-color: transparent;
}
.react-tel-input .form-control {
  width: 100%;
  background-color: transparent;
  color: white;
}

.react-tel-input .form-control:focus {
  background-color: transparent;
  color: white;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid white;
}

.react-tel-input {
  direction: ltr;
  background-color: transparent;
}

.css-yk16xz-control {
  background-color: transparent;
}

.flatpickr-input,
.date {
  // color: #232f53;
  font-size: 13px;
  font-family: Titillium Web;
  font-weight: bold;
  border-radius: 4px;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  width: 100%;
  min-height: 38px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  // overflow: hidden;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
}
.form-control::-webkit-input-placeholder {
  color: rgb(255, 255, 255);
}

.link::-webkit-input-placeholder {
  color: black;
}

::placeholder {
  color: rgb(255, 255, 255);
}

.css-1wa3eu0-placeholder:placeholder {
  color: rgb(255, 255, 255);
}

.form-control:-moz-placeholder {
  color: rgb(255, 255, 255);
}
.form-control::-moz-placeholder {
  color: rgb(255, 255, 255);
}
.form-control:-ms-input-placeholder {
  color: rgb(255, 255, 255);
}
.form-control::-ms-input-placeholder {
  color: rgb(255, 255, 255);
}
.react-datepicker {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  background-color: transparent;
}

.react-datepicker__header {
  background-color: white;
}

.react-datepicker-popper {
  width: 100%;
}
.react-datepicker__month {
  padding: 10;
  font-size: 13px;
  font-weight: 500px;
}

// .sidebar .nav-link {
//   border-bottom: 0.1px solid #f66c3e;
// }

.breadcrumb-item + .breadcrumb-item::before {
  color: white;
}

.brand-minimized .app-header .navbar-brand .navbar-brand-minimized {
  background-color: transparent;

  // background-image: linear-gradient(#2f4353, #121212);
}

.brand-minimized .app-header .navbar-brand {
  // background-color: #121212;
  background-color: transparent;
}

.MuiAppBar-colorPrimary {
  background-image: linear-gradient(#fa6e3f, #eee);
}

.MuiDialog-paperFullScreen {
  background-color: #0078de;
  // background-image: linear-gradient(#2f4353, #121212);
}

.search-control-active .search-control-close-button {
  display: none;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
#input-file {
  display: none;
}

.upload-btn:hover {
  display: block;
  text-align: center;
  margin: 20px auto 20px;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
  border: 2px solid #0078de;
  line-height: 30px;
  color: #0078de;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
}

.upload-btn {
  display: block;
  text-align: center;
  margin: 20px auto 20px;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #1e8bea9f;
  border: 2px solid white;
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div
  > div.sc-gtssRu.gfcMwa.rdt_TableHead
  > div
  > div:nth-child(2)
  .gcXkZv {
  color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div {
  background-color: transparent;
  color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div
  > div.sc-gtssRu.gfcMwa.rdt_TableHead {
  background-color: transparent;
  color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div
  > div.sc-gtssRu.gfcMwa.rdt_TableHead
  > div {
  background-color: transparent;
  color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-fnVYJo.lngAwT.rdt_TableHeader {
  background: transparent;
  color: white;
}

#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div:nth-child(3)
  > nav {
  background-image: linear-gradient(#585858, #808080);
  color: white;
}
#pagination-previous-page {
  background-color: white;
}
#pagination-previous-page {
  background-color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div
  > div.sc-dIvqjp.exodlN.rdt_TableBody
  .leBobi {
  background-image: linear-gradient(#585858, #808080);
  color: white;
  border-color: white;
}
.sc-hKFyIo .sc-eCApGN .sc-iqAbSa .fNiPDu .gcXkZv .dwHiXA .rdt_TableCol .fNiPDu {
  color: white;
  border-color: white;
}
.sc-kfYqjs .sc-bdnylx .sc-gtssRu .sc-dlnjPT .sc-hKFyIo .sc-crzoUp {
  color: white;
}
.sc-crzoUp .kmNxYI .rdt_TableCol_Sortable div {
  color: white;
}
#root
  > div
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(1)
  > div.col-lg-12
  > div.sc-hHEjAm.iECIgJ.fileTable
  > div
  > div
  > div.sc-gtssRu.gfcMwa.rdt_TableHead {
  background-image: linear-gradient(#585858, #808080);
  color: white;
}

.btn_right {
  float: right;
}

.react-select__placeholder {
  color: white;
}
.css-1wa3eu0-placeholder {
  color: white;
}

.inputGroup {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10px;
  min-height: 38px;
  justify-content: space-between;
  position: relative;
  text-align: center;
  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 10px;

    &:before {
      width: 100%;
      height: 100%;
      content: "";
      background-color: transparent;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
      border-radius: 10px;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
      border-radius: 10px;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #0078de;
      border-color: #0078de;
    }
  }

  input {
    width: 100%;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    border-radius: 10px;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
.MuiDialog-paperWidthLg {
  max-width: 1280px;
  width: 400px;
}
